.App {
  text-align: center;
  display: grid;
  grid: auto auto 1fr / 1fr 2fr 4fr;
  place-items: stretch;
  gap: 2vw;
}

.underline {
  text-decoration: underline;
}

.ingredients .highlight {
  background-color: lightblue;
}

.steps .highlight {
  background-color: lightblue;
}

.highlightBlock {
  background-color: black;
}

.recipeHeader {
  border: 1px solid black;
  border-top-width: 0;
  border-radius: 0 0 20px 20px;
  grid-column: 2 / 4;
  grid-row: 1;
  padding: 0 5%;
  margin: 0 40px;
}

.recipeList {
  grid-column: 1;
  grid-row: 1 / 4;
}

.recipeListItem {
  padding: 10px;
  margin: 5px auto;
  border: 1px solid black;
  border-left-width: 0;
}

.recipeListItem:hover {
  background-color: lightblue;
  color: black;
}

.recipeListItem.selected {
  background-color: lightblue;
  color: black;
  font-weight: bold;
}

.ingredients {
  grid-column: 2;
  grid-row: 2;
  justify-self: left;
  text-align: left;
  margin-left: 50px;
  padding: 0 5%;
}

.steps {
  grid-column: 3;
  grid-row: 2;
  justify-self: left;
  text-align: left;
  margin-right: 50px;
  padding: 0 5%;
}

.recipeBodyBackground {
  grid-column: 2 / 4;
  grid-row: 2;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  margin: 0 40px;
}

.wideCreationInput {
  width: 90%;
  margin: 0 auto;
}

.mediumCreationInput {
  width: 65%;
  margin: 0 auto;
}

.narrowCreationInput {
  width: 40%;
  margin: 0 auto;
}

@media(max-width: 1000px) {
  .recipeHeader {
    grid-column: 1 / 4;
  }

  .ingredients {
    grid-column: 1 / 4;
    margin-right: 50px;
  }

  .steps {
    grid-column: 1 / 4;
    grid-row: 3;
    margin-left: 50px;
    margin-bottom: 50px;
  }

  .recipeBodyBackground {
    grid-column: 1 / 4;
    grid-row: 2 / 4;
    margin-bottom: 40px;
  }
}